export function getDaasSegment(merchantSegment) {
    switch (merchantSegment != null && merchantSegment.toUpperCase()) {
        case "PLATINUM":
        case "TIER 7":
        case "TIER 8":
        case "TIER 9":
        case "AHLAN PLATINUM":
        case "AHLAN PLATINUM `":
        case "AHLAN PLATINUM*":
        case "AHLAN PLATINUM**":
        case "AHLAN PLATINUM P":
        case "AFFLUENT":
            return "Affluent";

        case "GOLD": 
        case "TIER 4": 
        case "TIER 5": 
        case "TIER 6": 
        case "AHLAN GOLD": 
        case "AHLAN GOLD `": 
        case "AHLAN GOLD*": 
        case "AHLAN GOLD**": 
        case "MASS_AFFLUENT":
            return "Mass Affluent";

        case "SILVER": 
        case "TIER 1": 
        case "TIER 2": 
        case "TIER 3": 
        case "AHLAN SILVER": 
        case "AHLAN SILVER `": 
        case "AHLAN SILVER*": 
        case "AHLAN SILVER**": 
        case "MASS":
            return "Mass";

        default:
            return "Mass Affluent";
    }
}

const empty = {
    utm_source : "",
    utm_medium : ""
}

const danube = {
    banner : {
        utm_source : ["danube-home"],
        utm_medium : ["home-page-banner", "profile-page-banner"],
    },
    catalog: { utm_source: [], utm_medium: [] }
}

const lulu = {
    banner : {
        utm_source : ["lulu-hypermarket"],
        utm_medium : ["home-page-banner", "order-confirmation-page-banner", "catalog-page-banner"],
    },
    catalog : {
        utm_source : ["lulu-hypermarket"],
        utm_medium : ["bank-products-tab"],
    }
}

const gulfNews = {
    banner : {
        utm_source : ["gulf-news"],
        utm_medium : ["travel-page-news-article-bottom-banner", "finance-page-news-article-bottom-banner", "catalog-page-banner", "generic-banner", "daily-news-briefing-email-banner"],
    },
    catalog : {
        utm_source : ["gulf-news"],
        utm_medium : ["classifieds-module-tab", "credit-card-deals-tab"],
    }
}

const gulfNewsClassified = {
    banner : {
        utm_source : ["gn-classified"],
        utm_medium : ["catalog-page-banner"],
    },
    catalog : {
        utm_source : ["gn-classified"],
        utm_medium : ["bank-deals-tab"],
    }
}

export function getUtmParametersByMerchantName(merchantName) {
    switch (merchantName != null && merchantName.toUpperCase()) {
        case "DANUBE HOME":
        case "DANUBE":
        case "DANUBE UAE":
        case "DANUBE HOME UAE":
            return danube;
        case "LULU":
        case "LULU HYPERMARKET UAE":
        case "LULU HYPERMARKET":
        case "LULU GROUP":
        case "LULU MONEY":
            return lulu;
        case "GULF NEWS":
        case "GN":
            return gulfNews;
        case "GULF NEWS CLASSIFIEDS":
        case "GN CLASSIFIEDS":
            return gulfNewsClassified;
        default:
            return empty;
    }
}

export function getDaaSDomain(env) {
    let daasApiDomain = 'https://daas-dev.ektar.io'
    if(env === 'localhost') {
      daasApiDomain = 'http://localhost:8000';
    } else if (env === 'uat') {
      daasApiDomain = 'https://daas-uat.ektar.io';
    } else if (env === 'demo') {
      daasApiDomain = 'https://daas-demo.ektar.io';
    }
    return daasApiDomain;
}