import React, { useEffect } from "react";
import daas from '@ektar-tech/daas-sdk-js';

import EktarIframeBanner from "./EktarIframeBanner";

function SixthStreetPage() {
    useEffect(() => {
        var daasEnv = localStorage.getItem("daas-env");

        var memberIdsObj = localStorage.getItem("merchantMemberIds");
        var memberIds = JSON.parse(memberIdsObj);
        var sixthStreetId = localStorage.getItem("SixthStreet");

        var dataPoint = new daas.com.ektar.daas_sdk.model.DataPoint();
        dataPoint.merchantId = sixthStreetId;
        dataPoint.email = localStorage.getItem("email_address");
        dataPoint.utmSource = "sixth-ae"
        dataPoint.utmMedium = "banner"

        var ektarBanner = new daas.com.ektar.daas_sdk.core.EktarBanner(dataPoint);
        ektarBanner.ektar_daas_env = daasEnv;
        ektarBanner.loadBanner();

    }, []);


    return (
        <div className="SixthStreetPage">
            <header>
            </header>
            <section>
                <div className="container my-5">
                    Sixth Street Pre-login Page
                    <EktarIframeBanner />
                    <div className="py-3" />
                </div>
            </section>
        </div>
    )
}

export default SixthStreetPage