import React, { useEffect } from "react";
import daas from '@ektar-tech/daas-sdk-js';

import EktarIframeBanner from "./EktarIframeBanner";

function TalabatPage() {

    useEffect(() => {
        var daasEnv = localStorage.getItem("daas-env");

        var mobileNumber = localStorage.getItem("mobile_number");
        var emailAddress = localStorage.getItem("email_address");
        var clientSegmentObj = localStorage.getItem("merchantSegments");
        var clientSegments = JSON.parse(clientSegmentObj);
        var memberIdsObj = localStorage.getItem("merchantMemberIds");
        var memberIds = JSON.parse(memberIdsObj);
        var talabatId = localStorage.getItem("Talabat");

        var dataPoint = new daas.com.ektar.daas_sdk.model.DataPoint();
        dataPoint.merchantId = talabatId;
        dataPoint.visitorId = emailAddress
        dataPoint.merchantCustomerSegment = clientSegments.talabat
        dataPoint.pageId = "d866e496-3e0a-479d-b902-8be9832afe36"
        dataPoint.dimension = "100X120"
        dataPoint.latLng = "96.0000, 83.89080"
        dataPoint.preferredLanguage = "ENGLISH_UK"
        dataPoint.nationality = "IN"
        dataPoint.age = 21
        dataPoint.ccBank = "HDFC"
        dataPoint.utmSource = "talabat-ae"
        dataPoint.utmMedium = "banner"

        var ektarBanner = new daas.com.ektar.daas_sdk.core.EktarBanner(dataPoint);
        ektarBanner.ektar_daas_env = daasEnv;
        ektarBanner.loadBanner();
        
    }, []);


    return (
        <div className="DanubePage">
            <header>
                {/* Jumbotron */}
                <div className="text-white" style={{
                    backgroundImage: 'url("https://customercarecentres.com/wp-content/uploads/2019/10/Talabat-Web-Banner.jpg")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    objectFit: "cover"
                }}>
                    <div className="container py-5" style={{ width: '100%', height: '350px' }}>
                    </div>
                </div>
                {/* Jumbotron */}
            </header>
            {/* Products */}
            <section>
                <div className="container">
                    <header className="mb-4">
                        <h4>Buy 1 Get 1 Free & discount offers</h4>
                    </header>

                    <EktarIframeBanner />
                    <div className="py-3" />                    

                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://simply-delicious-food.com/wp-content/uploads/2020/08/Bourbon-basted-cheeseburger-2-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Bourbon-basted double cheeseburger | Simply Delicious</h5>
                                    <p className="card-text">$790.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://nationaltoday.com/wp-content/uploads/2022/06/11-Pizza-Day-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Baby corn Pizza</h5>
                                    <p className="card-text">$320.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://thedessertedgirl.com/wp-content/uploads/2020/04/LoadedVeggiePizza3-320x320-1-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Loaded Veggie Pizza</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://indiasdeal.com/wp-content/uploads/2021/10/Coca-Cola-Fridge-Pack-1.25-L-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Coca Cola Mobile Pet, 750 ml</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://yummyies.com/wp-content/uploads/2021/03/vegroll-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">VEG ROLL - YUMMYIES</h5>
                                    <p className="card-text">$790.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://media1.agfg.com.au/images/recipes/1412/hero-300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Achari Paneer Tikka</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://www.peelwithzeal.com/wp-content/uploads/2020/04/Shawarma-Chicken-Wings-Recipe-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Crispy Shawarma Chicken Wings - Peel with Zeal</h5>
                                    <p className="card-text">$99.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border icon-hover px-2 pt-2"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://lifeloveandgoodfood.com/wp-content/uploads/2020/04/Chicken-Shawarma_09_1200x1200-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Chicken Shawarma - Life, Love, and Good Food</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Products */}
        </div>
    )
}

export default TalabatPage