package com.ektar.daas_sdk.utils

actual class Logger {

    actual companion object {

        actual fun logDebug(tag: String, message: String) {
            console.log("$tag : $message")
        }
		
        actual fun logError(tag: String, message: String) {
            console.log("$tag : $message")
        }

		@Suppress("unused")
		actual fun logError(tag: String, message: String, exception: Throwable) {
            console.log("$tag : $message")
            console.log("${exception.message}")
        }
    }
}
