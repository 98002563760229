package com.ektar.daas_sdk.model

import com.ektar.daas_sdk.core.EktarBanner
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlin.jvm.JvmSynthetic

@OptIn(ExperimentalJsExport::class)
@JsExport
@Serializable
class CatalogDataPoint : DataPoint() {

	@SerialName("sc")
	var sourcingChannel: String = "CAT"

	@SerialName("cpb")
	var channelPartnerBranch: String = ""

	@SerialName("sp")
	var salesPerson: String = ""

	@SerialName("sl")
	var showLogo: Boolean = true

	@SerialName("sf")
	var showFooter: Boolean = true

	@JvmSynthetic
	internal fun buildOfferCatalogUrl(banner: EktarBanner): String {
		val url = buildGetOfferRequestUrl(banner)

		val cUrl = StringBuilder(url)
		cUrl.append("&")

		if (channelPartnerBranch.isNotBlank()) cUrl.append("cpb").append("=")
			.append(banner.encodeUrl(channelPartnerBranch)).append("&")

		if (salesPerson.isNotBlank()) cUrl.append("sp").append("=")
			.append(banner.encodeUrl(salesPerson)).append("&")

		if (sourcingChannel.isNotBlank()) cUrl.append("sc").append("=")
			.append(banner.encodeUrl(sourcingChannel)).append("&")

		cUrl.append("sl").append("=").append(showLogo).append("&")
		cUrl.append("sf").append("=").append(showFooter).append("&")
		cUrl.deleteAt(cUrl.length - 1)
		return cUrl.toString()
	}
}
