class OAuth2 {
    
    apiDomain;

    constructor() {
        this.apiDomain = window.localStorage.getItem('daas-api-domain');
    }

     callAccessTokenApi(resolve, reject) {
        const OAuth2 = this
        const xhr = new XMLHttpRequest();
        xhr.open('POST', this.apiDomain + '/oauth2/token', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        var data = {
            grant_type: 'password',
            username: 'ram@ektar.com',
            password: 'ram123$#'
          };
        var urlEncodedData = Object.keys(data).map(function(key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
        }).join('&');
        xhr.onload = function() {
          if (xhr.status === 200) {
            OAuth2.saveAccessToken(xhr.responseText, resolve, reject)
          }
        };
        xhr.onerror = function() {
            console.error('Request error');
          };
        xhr.send(urlEncodedData);
    }

    saveAccessToken(response, resolve, reject) {
        try {
            var result = JSON.parse(response)
            result.expires_in = new Date(new Date().getTime() + (result.expires_in * 1000))
            window.localStorage.setItem("oauth2-response", JSON.stringify(result))
            resolve(result.access_token)
        } catch(e) {
            reject(e)
        }
    }

    getAccessToken() {
        return new Promise((resolve, reject) => {
            var response = window.localStorage.getItem("oauth2-response")
            if (response !== null && response !== undefined) {
                this.validateAndGetToken(response, resolve, reject)
            } else {
                this.callAccessTokenApi(resolve, reject)
            }
          });
    }

    validateAndGetToken(response, resolve, reject) {
        var result = JSON.parse(response)
        var expires = result.expires_in

        let expiresDateTime = new Date(expires) > new Date();
        if(expiresDateTime) {
            resolve(result.access_token)
        } else {
            this.callAccessTokenApi(resolve, reject)
        }
    }
}

export default OAuth2