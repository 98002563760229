package com.ektar.daas_sdk.utils

import kotlin.js.*

class Utility {
	companion object {
		fun getSdkVersion(): String {
			val packageVersion = js("require('../package.json').version").unsafeCast<String?>()
			return packageVersion ?: ""
		}

		fun getPlatform(): String {
			return "W"
		}
	}
}
