package com.ektar.daas_sdk.core

import com.ektar.daas_sdk.model.DataPoint
import com.ektar.daas_sdk.utils.ApiConstants
import com.ektar.daas_sdk.utils.Logger
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.cookies.AcceptAllCookiesStorage
import io.ktor.client.plugins.cookies.HttpCookies
import io.ktor.client.request.*
import io.ktor.client.statement.HttpResponse
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.serialization.kotlinx.json.json
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch

abstract class CommonEktarBanner(var dp: DataPoint) {

	var listener: BannerListener? = null

	private var client: HttpClient = HttpClient {
		install(HttpCookies) { storage = AcceptAllCookiesStorage() }
		install(ContentNegotiation) { json() }
		install(HttpTimeout) {
			socketTimeoutMillis = 60000
			requestTimeoutMillis = 60000
			connectTimeoutMillis = 60000
		}
	}

	@OptIn(DelicateCoroutinesApi::class)
	fun getBanner(apiListener: ApiListener) {
		val url = ApiConstants.GET_OFFER_API
		GlobalScope.launch(Dispatchers.Main) {
			try {
				val response: HttpResponse = client.post(url) {
					contentType(ContentType.Application.Json)
					setBody(dp)
				}
				val jsonValue: String = response.body()!!
				apiListener.onSuccess(jsonValue)
			} catch (e: Exception) {
				Logger.logError("getBanner", e.toString())
				apiListener.onFailure(e.toString())
			}
		}
	}

}
