import '../App.css';
import packageJson from '../../package.json';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useEffect, useState } from "react";
import HomePage from './Home';
import DanubePage from './DanubePage';
import StarbucksPage from './StarbucksPage';
import TalabatPage from './TalabatPage';
import SiemensPage from './SiemensPage';
import MyProfilePage from './MyProfilePage';
import SixthStreetPage from './SixthStreetPage';
import OfferCatalogPage from './OfferCatalogPage';
import { getDaasSegment } from '../utils';
import { Route, Routes, useNavigate } from 'react-router-dom';

import TagManager from "react-gtm-module";
import { getGtmId } from '../config'
import TestBannerAndCatalog from './TestBannerCatalogPage';


function DashboardPage() {
    const [segments, setSegments] = useState([]);
    const [memberIds, setMemberIds] = useState([]);

    const gtmId = getGtmId(); 

    useEffect(() => {
        TagManager.initialize({ gtmId: gtmId });
        
        var merchantSegments = window.localStorage.getItem('merchantSegments');
        setSegments(JSON.parse(merchantSegments))

        var merchantMemberIds = window.localStorage.getItem('merchantMemberIds')
        setMemberIds(JSON.parse(merchantMemberIds))
      }, []);

    const navigate = useNavigate();
    const navigateLogin = () => navigate('/');

    function handleLogout() {
        window.localStorage.clear()
        navigateLogin();
    }

    return (
        <div className="DashboardPage">
            {/*Main Navigation*/}
            <header>
                {/* Jumbotron */}
                <div className="p-3 text-center bg-white border-bottom">
                    <div className="container">
                        <div className="row gy-3">
                            {/* Left elements */}
                            <div className="col-lg-2 col-sm-4 col-4">
                                <a href="https://mdbootstrap.com/" target="_blank" className="float-start">
                                    <img src="https://www.freepnglogos.com/uploads/flipkart-logo-png/flipkart-com-logo-internet-ltd-state-of-kerala-10.png" height={50} />
                                </a>
                            </div>
                            {/* Left elements */}
                            {/* Center elements */}
                            <div className="order-lg-last col-lg-5 col-sm-8 col-8">
                                <div className="d-flex float-end">
                                    <a className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center text-white fw-bold" style={{ backgroundColor: 'red' }}>
                                        <p className="d-none d-md-block mb-0">{window.localStorage.getItem('daas-env').toUpperCase()}</p>
                                    </a>
                                    <a className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center text-white fw-bold" style={{ backgroundColor: 'red' }}>
                                        <p className="d-none d-md-block mb-0">{packageJson.version}</p>
                                    </a>
                                    <a href="https://github.com/mdbootstrap/bootstrap-material-design" className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center" target="_blank"> <i className="fas fa-heart m-1 me-md-2" /><p className="d-none d-md-block mb-0">Wishlist</p> </a>
                                    <a href="https://github.com/mdbootstrap/bootstrap-material-design" className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center" target="_blank"> <i className="fas fa-shopping-cart m-1 me-md-2" /><p className="d-none d-md-block mb-0">My cart</p> </a>
                                    <button onClick={handleLogout} className="border rounded py-1 px-3 nav-link d-flex align-items-center bg-white" target="_blank"> <i className="fas fa-sign-out m-1 me-md-2" /><p className="d-none d-md-block mb-0">Logout</p> </button>
                                </div>
                            </div>
                            {/* Center elements */}
                            {/* Right elements */}
                            <div className="col-lg-5 col-md-12 col-12">
                                <div className="input-group float-center">
                                    <div className="form-outline">
                                        <input type="search" id="form1" className="form-control" />
                                        <label className="form-label" htmlFor="form1">Search</label>
                                    </div>
                                    <button type="button" className="btn btn-primary shadow-0">
                                        <i className="fas fa-search" />
                                    </button>
                                </div>
                            </div>
                            {/* Right elements */}
                        </div>
                    </div>
                </div>
                {/* Jumbotron */}
                {/* Navbar */}
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    {/* Container wrapper */}
                    <div className="container-fluid">
                        {/* Toggle button */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars" />
                        </button>
                        {/* Collapsible wrapper */}
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {/* Left links */}
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link text-dark" aria-current="page" href="/dashboard/home">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark text-center" aria-current="page" href="/dashboard/danube">Danube (member-id) <br></br>({getDaasSegment(segments.danube)})</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark text-center" href="/dashboard/starbucks">Starbucks (mobile)  <br></br>({getDaasSegment(segments.starbucks)})</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark text-center" href="/dashboard/talabat">Talabat (email) <br></br>({getDaasSegment(segments.talabat)})</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark text-center" href="/dashboard/siemens">Siemens (Employer Portal/Email) <br></br>({getDaasSegment(segments.siemens)})</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-dark text-center" href="/dashboard/6th-street">6th Street (Pre-login)  <br></br>({getDaasSegment(segments.sixthstreet)})</a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark text-center' href="/dashboard/test-banner-catalog">Dynamic <br></br> Banner & Catalog</a>
                                </li>
                            </ul>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link text-dark" href="/dashboard/my-profile">My Profile</a>
                                </li>
                            </ul>
                            {/* Left links */}
                        </div>
                    </div>
                    {/* Container wrapper */}
                </nav>
                {/* Navbar */}
            </header>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/danube" element={<DanubePage />} />
                <Route path="/starbucks" element={<StarbucksPage />} />
                <Route path="/talabat" element={<TalabatPage />} />
                <Route path="/siemens" element={<SiemensPage />} />
                <Route path="/6th-Street" element={<SixthStreetPage />} />
                <Route path="/offer-catalog" element={<OfferCatalogPage />} />
                <Route path='/test-banner-catalog' element={<TestBannerAndCatalog />} />
                <Route path="/my-profile" element={<MyProfilePage />} />
            </Routes>
            {/* Feature */}
            <section className="mt-5" style={{ backgroundColor: '#f5f5f5' }}>
                <div className="container text-dark pt-3">
                    <header className="pt-4 pb-3">
                        <h3>Why choose us</h3>
                    </header>
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6">
                            <figure className="d-flex align-items-center mb-4">
                                <span className="rounded-circle bg-white p-3 d-flex me-2 mb-2">
                                    <i className="fas fa-camera-retro fa-2x fa-fw text-primary floating" />
                                </span>
                                <figcaption className="info">
                                    <h6 className="title">Reasonable prices</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor</p>
                                </figcaption>
                            </figure>
                            {/* itemside // */}
                        </div>
                        {/* col // */}
                        <div className="col-lg-4 col-md-6">
                            <figure className="d-flex align-items-center mb-4">
                                <span className="rounded-circle bg-white p-3 d-flex me-2 mb-2">
                                    <i className="fas fa-star fa-2x fa-fw text-primary floating" />
                                </span>
                                <figcaption className="info">
                                    <h6 className="title">Best quality</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor</p>
                                </figcaption>
                            </figure>
                            {/* itemside // */}
                        </div>
                        {/* col // */}
                        <div className="col-lg-4 col-md-6">
                            <figure className="d-flex align-items-center mb-4">
                                <span className="rounded-circle bg-white p-3 d-flex me-2 mb-2">
                                    <i className="fas fa-plane fa-2x fa-fw text-primary floating" />
                                </span>
                                <figcaption className="info">
                                    <h6 className="title">Worldwide shipping</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor</p>
                                </figcaption>
                            </figure>
                            {/* itemside // */}
                        </div>
                        {/* col // */}
                        <div className="col-lg-4 col-md-6">
                            <figure className="d-flex align-items-center mb-4">
                                <span className="rounded-circle bg-white p-3 d-flex me-2 mb-2">
                                    <i className="fas fa-users fa-2x fa-fw text-primary floating" />
                                </span>
                                <figcaption className="info">
                                    <h6 className="title">Customer satisfaction</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor</p>
                                </figcaption>
                            </figure>
                            {/* itemside // */}
                        </div>
                        {/* col // */}
                        <div className="col-lg-4 col-md-6">
                            <figure className="d-flex align-items-center mb-4">
                                <span className="rounded-circle bg-white p-3 d-flex me-2 mb-2">
                                    <i className="fas fa-thumbs-up fa-2x fa-fw text-primary floating" />
                                </span>
                                <figcaption className="info">
                                    <h6 className="title">Happy customers</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor</p>
                                </figcaption>
                            </figure>
                            {/* itemside // */}
                        </div>
                        {/* col // */}
                        <div className="col-lg-4 col-md-6">
                            <figure className="d-flex align-items-center mb-4">
                                <span className="rounded-circle bg-white p-3 d-flex me-2 mb-2">
                                    <i className="fas fa-box fa-2x fa-fw text-primary floating" />
                                </span>
                                <figcaption className="info">
                                    <h6 className="title">Thousand items</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor</p>
                                </figcaption>
                            </figure>
                            {/* itemside // */}
                        </div>
                        {/* col // */}
                    </div>
                </div>
                {/* container end.// */}
            </section>
            {/* Feature */}
            {/* Blog */}
            <section className="mt-5 mb-4">
                <div className="container text-dark">
                    <header className="mb-4">
                        <h3>Blog posts</h3>
                    </header>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <article>
                                <a href="#" className="img-fluid">
                                    <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                </a>
                                <div className="mt-2 text-muted small d-block mb-1">
                                    <span>
                                        <i className="fa fa-calendar-alt fa-sm" />
                                        23.12.2022
                                    </span>
                                    <a href="#"><h6 className="text-dark">How to promote brands</h6></a>
                                    <p>When you enter into any new area of science, you almost reach</p>
                                </div>
                            </article>
                        </div>
                        {/* col.// */}
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <article>
                                <a href="#" className="img-fluid">
                                <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                </a>
                                <div className="mt-2 text-muted small d-block mb-1">
                                    <span>
                                        <i className="fa fa-calendar-alt fa-sm" />
                                        13.12.2022
                                    </span>
                                    <a href="#"><h6 className="text-dark">How we handle shipping</h6></a>
                                    <p>When you enter into any new area of science, you almost reach</p>
                                </div>
                            </article>
                        </div>
                        {/* col.// */}
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <article>
                                <a href="#" className="img-fluid">
                                <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                </a>
                                <div className="mt-2 text-muted small d-block mb-1">
                                    <span>
                                        <i className="fa fa-calendar-alt fa-sm" />
                                        25.11.2022
                                    </span>
                                    <a href="#"><h6 className="text-dark">How to promote brands</h6></a>
                                    <p>When you enter into any new area of science, you almost reach</p>
                                </div>
                            </article>
                        </div>
                        {/* col.// */}
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <article>
                                <a href="#" className="img-fluid">
                                <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                </a>
                                <div className="mt-2 text-muted small d-block mb-1">
                                    <span>
                                        <i className="fa fa-calendar-alt fa-sm" />
                                        03.09.2022
                                    </span>
                                    <a href="#"><h6 className="text-dark">Success story of sellers</h6></a>
                                    <p>When you enter into any new area of science, you almost reach</p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            {/* Blog */}
            {/* Footer */}
            <footer className="text-center text-lg-start text-muted mt-3" style={{ backgroundColor: '#f5f5f5' }}>
                {/* Section: Links  */}
                <section className>
                    <div className="container text-center text-md-start pt-4 pb-4">
                        {/* Grid row */}
                        <div className="row mt-3">
                            {/* Grid column */}
                            <div className="col-12 col-lg-3 col-sm-12 mb-2">
                                {/* Content */}
                                <a href="https://mdbootstrap.com/" target="_blank" className>
                                    <img src="https://www.freepnglogos.com/uploads/flipkart-logo-png/flipkart-com-logo-internet-ltd-state-of-kerala-10.png" height={50} />
                                </a>
                                <p className="mt-2 text-dark">
                                    © 2023 Copyright: Flipkart.com
                                </p>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-6 col-sm-4 col-lg-2">
                                {/* Links */}
                                <h6 className="text-uppercase text-dark fw-bold mb-2">
                                    Store
                                </h6>
                                <ul className="list-unstyled mb-4">
                                    <li><a className="text-muted" href="#">About us</a></li>
                                    <li><a className="text-muted" href="#">Find store</a></li>
                                    <li><a className="text-muted" href="#">Categories</a></li>
                                    <li><a className="text-muted" href="#">Blogs</a></li>
                                </ul>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-6 col-sm-4 col-lg-2">
                                {/* Links */}
                                <h6 className="text-uppercase text-dark fw-bold mb-2">
                                    Information
                                </h6>
                                <ul className="list-unstyled mb-4">
                                    <li><a className="text-muted" href="#">Help center</a></li>
                                    <li><a className="text-muted" href="#">Money refund</a></li>
                                    <li><a className="text-muted" href="#">Shipping info</a></li>
                                    <li><a className="text-muted" href="#">Refunds</a></li>
                                </ul>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-6 col-sm-4 col-lg-2">
                                {/* Links */}
                                <h6 className="text-uppercase text-dark fw-bold mb-2">
                                    Support
                                </h6>
                                <ul className="list-unstyled mb-4">
                                    <li><a className="text-muted" href="#">Help center</a></li>
                                    <li><a className="text-muted" href="#">Documents</a></li>
                                    <li><a className="text-muted" href="#">Account restore</a></li>
                                    <li><a className="text-muted" href="#">My orders</a></li>
                                </ul>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-12 col-sm-12 col-lg-3">
                                {/* Links */}
                                <h6 className="text-uppercase text-dark fw-bold mb-2">Newsletter</h6>
                                <p className="text-muted">Stay in touch with latest updates about our products and offers</p>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control border" placeholder="Email" aria-label="Email" aria-describedby="button-addon2" />
                                    <button className="btn btn-light border shadow-0" type="button" id="button-addon2" data-mdb-ripple-color="dark">
                                        Join
                                    </button>
                                </div>
                            </div>
                            {/* Grid column */}
                        </div>
                        {/* Grid row */}
                    </div>
                </section>
                {/* Section: Links  */}
                <div className>
                    <div className="container">
                        <div className="d-flex justify-content-between py-4 border-top">
                            {/*- payment -*/}
                            <div>
                                <i className="fab fa-lg fa-cc-visa text-dark" />
                                <i className="fab fa-lg fa-cc-amex text-dark" />
                                <i className="fab fa-lg fa-cc-mastercard text-dark" />
                                <i className="fab fa-lg fa-cc-paypal text-dark" />
                            </div>
                            {/*- payment -*/}
                            {/*- language selector -*/}
                            <div className="dropdown dropup">
                                <a className="dropdown-toggle text-dark" href="#" id="Dropdown" role="button" data-mdb-toggle="dropdown" aria-expanded="false"> <i className="flag-united-kingdom flag m-0 me-1" />English </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="Dropdown">
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-united-kingdom flag" />English <i className="fa fa-check text-success ms-2" /></a>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-poland flag" />Polski</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-china flag" />中文</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-japan flag" />日本語</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-germany flag" />Deutsch</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-france flag" />Français</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-spain flag" />Español</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-russia flag" />Русский</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#"><i className="flag-portugal flag" />Português</a>
                                    </li>
                                </ul>
                            </div>
                            {/*- language selector -*/}
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer */}
        </div>
    );
}

export default DashboardPage;