export const getGtmId = () => {

    const origin = window.localStorage.getItem('daas-env') || 'dev';
  
    const config = {
      'dev': process.env.REACT_APP_GTM_ID_DEV,
      'uat': process.env.REACT_APP_GTM_ID_UAT,
      'demo': process.env.REACT_APP_GTM_ID_DEMO,
    };
  
    return config[origin] || process.env.REACT_APP_GTM_ID_DEV;
  };