import React from "react";

function HomePage() {
    return (
        <div className="Home">
            <header>
                {/* Jumbotron */}
                <div className="bg-primary text-white py-5">
                    <div className="container py-5">
                        <h1>
                            Best products &amp; <br />
                            brands in our store
                        </h1>
                        <p>
                            Trendy Products, Factory Prices, Excellent Service
                        </p>
                        <button type="button" className="btn btn-outline-light">
                            Learn more
                        </button>
                        <button type="button" className="btn btn-light shadow-0 text-primary pt-2 border border-white">
                            <span className="pt-1">Purchase now</span>
                        </button>
                    </div>
                </div>
                {/* Jumbotron */}
            </header>
            {/* Products */}
            <section>
                <div className="container my-5">
                    <header className="mb-4">
                        <h3>New products</h3>
                    </header>
                    <div id='ek_single_banner'></div>
                    <div className="mb-4"></div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">GoPro HERO6 4K Action Camera - Black</h5>
                                    <p className="card-text">$790.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Canon camera 20x zoom, Black color EOS 2000</h5>
                                    <p className="card-text">$320.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Xiaomi Redmi 8 Original Global Version 4GB</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Apple iPhone 12 Pro 6.1" RAM 6GB 512GB Unlocked</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Apple Watch Series 1 Sport Case 38mm Black</h5>
                                    <p className="card-text">$790.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">T-shirts with multiple colors, for men and lady</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Gaming Headset 32db Blackbuilt in mic</h5>
                                    <p className="card-text">$99.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border icon-hover px-2 pt-2"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                            <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">T-shirts with multiple colors, for men and lady</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Products */}
        </div>
    )
}

export default HomePage