import { FormGroup, FormLabel, FormControl, Table, Button} from 'react-bootstrap';
import { useEffect, useState } from "react";
import { getDaasSegment } from '../utils';

function MyProfilePage() {
    const [segments, setSegments] = useState([]);
    const [memberIds, setMemberIds] = useState([]);

    function getApiUrlForEnv() {
        var env = localStorage.getItem("daas-env");
        var daasApiDomain = 'https://daas-dev.ektar.io'
        if(env === 'localhost') {
          daasApiDomain = 'http://localhost:8000';
        } else if (env === 'uat') {
          daasApiDomain = 'https://daas-uat.ektar.io';
        } else if (env === 'demo') {
          daasApiDomain = 'https://daas-demo.ektar.io';
        }

        return daasApiDomain;
    }

    function clearEkid() {
        // Clear the cookie with name ekid
        document.cookie = "ekid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.localStorage.removeItem('ekid');

        window.localStorage.removeItem('ekid');
        window.localStorage.removeItem('ekidFromCookie');

        let iframeDiv = document.getElementById('ekid-manage');
        if (document.querySelector('#ekid-manage iframe')) {
            document.querySelector('#ekid-manage iframe').src = getApiUrlForEnv() + '/offers-engine/ekid-manage';
        } else {
            let iframeElement = document.createElement('iframe');
            iframeDiv.appendChild(iframeElement);
            iframeElement.style.display = 'none';
            iframeElement.src = getApiUrlForEnv() + '/offers-engine/ekid-manage';
        }
    }
    useEffect(() => {
        var merchantSegments = window.localStorage.getItem('merchantSegments');
        setSegments(JSON.parse(merchantSegments))

        var merchantMemberIds = window.localStorage.getItem('merchantMemberIds')
        setMemberIds(JSON.parse(merchantMemberIds))
      }, []);
    return (
        <section>
            <div className="container my-5">
            <div className='row'>
                    <div className='col-12 fw-bold'>ekId (Cookie/Local Storage) Administration:</div>
                    <div className='py-1' />
                    <FormGroup className='mb-3 col-6'> 
                        <FormLabel className="text-black"></FormLabel>
                        <Button
                            variant="danger"
                            name="clear_ekid"
                            id="clear_ekid"
                            onClick={clearEkid}>Clear EkId </Button>
                    </FormGroup>
                </div>
                <FormGroup className="mb-3">
                    <FormLabel className="text-black">User Profile - Id:</FormLabel>
                    <FormControl placeholder={window.localStorage.getItem('user_id')} disabled />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel className="text-black">Name:</FormLabel>
                    <FormControl placeholder={window.localStorage.getItem('name')} disabled />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel className="text-black">Mobile Number:</FormLabel>
                    <FormControl placeholder={window.localStorage.getItem('mobile_number')} disabled />
                </FormGroup>
                <FormGroup className="mb-5">
                    <FormLabel className="text-black">Email Address:</FormLabel>
                    <FormControl placeholder={window.localStorage.getItem('email_address')} disabled />
                </FormGroup>
                <FormGroup className="mb-5">
                    <FormLabel className="text-black">Merchant Segments:</FormLabel>
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th className="text-black fw-bold col-6">Merchant</th>
                        <th className="text-black fw-bold">Merchant Segment</th>
                        <th className="text-black fw-bold">DaaS Segment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Talabat</td>
                            <td>{segments.talabat}</td>
                            <td>{getDaasSegment(segments.talabat)}</td>
                        </tr>
                        <tr>
                            <td>Starbucks</td>
                            <td>{segments.starbucks}</td>
                            <td>{getDaasSegment(segments.starbucks)}</td>
                        </tr>
                        <tr>
                            <td>Danube</td>
                            <td>{segments.danube}</td>
                            <td>{getDaasSegment(segments.danube)}</td>
                        </tr>    
                        <tr>
                            <td>Siemens</td>
                            <td>{segments.siemens}</td>
                            <td>{getDaasSegment(segments.siemens)}</td>
                        </tr>   
                        <tr>
                            <td>SixthStreet</td>
                            <td>N/A [Pre-login]</td>
                            <td>Defaulting to Mass Affluent</td>
                        </tr>   
                    </tbody>
                </Table>
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel className="text-black">Merchant MemberIds:</FormLabel>
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th className="text-black fw-bold col-6">Merchant</th>
                        <th className="text-black fw-bold">MemberId</th>
                        <th className="text-black fw-bold">Visitor Id Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Talabat</td>
                            <td>{memberIds.talabat}</td>
                            <td>Email</td>
                        </tr>
                        <tr>
                            <td>Starbucks</td>
                            <td>{memberIds.starbucks}</td>
                            <td>Mobile Number</td>
                        </tr>
                        <tr>
                            <td>Danube</td>
                            <td>{memberIds.danube}</td>
                            <td>Member Id</td>
                        </tr>    
                        <tr>
                            <td>Siemens</td>
                            <td>{memberIds.siemens}</td>
                            <td>Email</td>
                        </tr>   
                        <tr>
                            <td>SixthStreet</td>
                            <td>N/A [Pre-login]</td>
                            <td>Email</td>
                        </tr>  
                    </tbody>
                </Table>
                </FormGroup>
            </div>
            <div id='ekid-manage'></div>
        </section>     
    )
}



export default MyProfilePage;