package com.ektar.daas_sdk.utils

import kotlin.native.concurrent.ThreadLocal

open class ApiConstants {
	@ThreadLocal
	companion object {
		//Environment key
		var EKTAR_DAAS_ENV = "ektar_daas_env"

		//Base urls
		private var PRODUCTION_URL: String = "https://daas.ektar.io"
		private var DEVELOPMENT_URL: String = "https://daas-dev.ektar.io"
		private var UAT_URL: String = "https://daas-uat.ektar.io"
		private var DEMO_URL: String = "https://daas-demo.ektar.io"
		private var LOCALHOST_URL: String = "http://localhost:8000"

		// APIs
		var GET_OFFER_API: String = ""
		var GET_OFFER_API_V2: String = ""
		var GET_OFFER_CATALOG_API: String = ""
		var GET_EKID_API: String = ""

		//Environment variables
		private var DEV: String = "dev"
		private var PROD: String = "prod"
		private var UAT: String = "uat"
		private var LOCALHOST: String = "localhost"
		private var DEMO: String = "demo"

		//messages
		var envNotProvidedMessage: String =
			"Environment config is not provided, defaulting to prod, Please pass ektar_daas_env " +
				"= prod | uat | dev | localhost | demo"
		var viewNotProvidedMessage: String =
			"View must not to be empty. please pass the view in into method"

		private fun setBaseUrl(environment: String) {
			var baseUrl = ""
			when (environment) {
				DEV -> baseUrl = DEVELOPMENT_URL
				UAT -> baseUrl = UAT_URL
				PROD -> baseUrl = PRODUCTION_URL
				LOCALHOST -> baseUrl = LOCALHOST_URL
				DEMO -> baseUrl = DEMO_URL
			}
			GET_OFFER_API = "$baseUrl/merchant-offers"
			GET_OFFER_API_V2 = "$baseUrl/offers-engine/merchant-offers"
			GET_OFFER_CATALOG_API = "$baseUrl/microsite/offer-catalog/start"
			GET_EKID_API = "$baseUrl/offers-engine/get-ekid"
		}

		fun setEnvironment(environment: String) {
			if (environment.isEmpty() || (environment != DEV && environment != UAT &&
					environment != PROD && environment != LOCALHOST && environment != DEMO)
			) {
				Logger.logError("Environment not provided", envNotProvidedMessage)
			} else {
				setBaseUrl(environment)
			}
		}

		fun isDaasProdOrNonProdUrl(url: String): Boolean {
			return url.isNotBlank() && (url == PRODUCTION_URL || url == UAT_URL ||
			url == DEVELOPMENT_URL || url == LOCALHOST_URL || url == DEMO_URL)
		}
	}
}
