import React, { useEffect, useState } from "react";
import { FormLabel, Button } from "react-bootstrap";
import Select from "react-select";
import daas from "@ektar-tech/daas-sdk-js";
import { getUtmParametersByMerchantName } from "../utils";

import EktarIframeBanner from "./EktarIframeBanner";
import OAuth2 from "./OAuth2";
import EktarOfferCatalog from "./EktarOfferCatalog";
import { getDaaSDomain } from "../utils";
import EktarApiBanner from "./EktarApiBanner";

function TestBannerAndCatalog() {
  const apiDomain = window.localStorage.getItem("daas-api-domain");

  const [bannerDimensions, setBannerDimensions] = useState(null);
  const [merchants, setMerchants] = useState(null);

  const [utmParameters, setUtmParameters] = useState(null);

  const [bannerUtmSource, setBannerUtmSource] = useState(null);
  const [bannerUtmMedium, setBannerUtmMedium] = useState(null);

  const [catalogUtmSource, setCatalogUtmSource] = useState(null);
  const [catalogUtmMedium, setCatalogUtmMedium] = useState(null);

  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedDimension, setSelectedDimension] = useState(null);

  const [loadBannerVia, setLoadBannerVia] = useState("sdk");
  const [loadOption, setLoadOption] = useState("sb");
  const [bannerResponse, setBannerResponse] = useState(null);

  const SHOW_CATALOG = "sc";
  const SHOW_BANNER = "sb";
  const SHOW_CATALOG_AND_BANNER = "scb";

  function getBannerDimensions(accessToken) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", apiDomain + "/rdm/banner-dimensions?size=100");
    xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
    xhr.onload = function () {
      if (xhr.status === 200) {
        setBannerDimensions(JSON.parse(xhr.responseText).bannerDimensions);
      }
    };
    xhr.send();
  }

  function getMerchants(accessToken) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", apiDomain + "/merchants?size=100");
    xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
    xhr.onload = function () {
      if (xhr.status === 200) {
        var localMerchants = JSON.parse(xhr.responseText).merchants;
        var array = localMerchants.map((merchant) => ({
          value: merchant.id,
          label: merchant.name,
        }));
        setMerchants(array);
      }
    };
    xhr.send();
  }

  function handleToLoadBannerAndCatalog() {
    if (loadOption === SHOW_CATALOG) {
      loadCatalog();
    } else if (loadOption === SHOW_BANNER) {
      loadBanner();
    } else if (loadOption === SHOW_CATALOG_AND_BANNER) {
      loadCatalog();
      loadBanner();
    }
  }

  function loadCatalog() {
    if (selectedMerchant == null || selectedMerchant === "") {
      console.log("Select Merchant");
      return;
    }

    clearCatalog();

    var daasEnv = localStorage.getItem("daas-env");

    var dataPoint = new daas.com.ektar.daas_sdk.model.CatalogDataPoint();
    dataPoint.merchantId = selectedMerchant;
    dataPoint.showLogo = true;
    dataPoint.showFooter = false;
    dataPoint.subscriber = true;
    if(catalogUtmSource) dataPoint.utmSource = catalogUtmSource.trim()
    if(catalogUtmMedium) dataPoint.utmMedium = catalogUtmMedium.trim()

    var ektarCatalog = new daas.com.ektar.daas_sdk.core.EktarCatalog(dataPoint);
    ektarCatalog.ektar_daas_env = daasEnv;
    ektarCatalog.loadOfferCatalog();
  }

  function loadBanner() {
    clearBanner();
    if (loadBannerVia === "api") {
      loadBannerWithApi();
    } else {
      loadBannerWithSdk();
    }
  }

  function loadBannerWithSdk() {
    if (selectedMerchant == null || selectedMerchant === "") {
      console.log("Select Merchant");
      return;
    }

    var banner = document.getElementById("ek_single_banner_iframe");
    var daasEnv = localStorage.getItem("daas-env");

    var dataPoint = new daas.com.ektar.daas_sdk.model.DataPoint();
    dataPoint.merchantId = selectedMerchant;
    if (selectedLanguage != null && selectedLanguage !== "")
      dataPoint.preferredLanguage = selectedLanguage;
    if (selectedDimension != null && selectedDimension !== "") {
      dataPoint.dimension = selectedDimension;
      var dimensions = selectedDimension.toUpperCase().split("X");
      banner.style.width = dimensions[0] + "px";
      banner.style.height = dimensions[1] + "px";
    } else {
      banner.style.width = "100%";
    }
    if(bannerUtmSource) dataPoint.utmSource = bannerUtmSource.trim()
    if(bannerUtmMedium) dataPoint.utmMedium = bannerUtmMedium.trim()

    var ektarBanner = new daas.com.ektar.daas_sdk.core.EktarBanner(dataPoint);
    ektarBanner.ektar_daas_env = daasEnv;
    ektarBanner.loadBanner();
  }

  function loadBannerWithApi() {
    const url = buildGetBannerUrl();
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onload = function () {
      if (xhr.status === 200) {
        setBannerResponse(JSON.parse(xhr.responseText));
      }
    };
    xhr.send();
  }

  function clearBanner() {
    var sdkBanner = document.getElementById("ek_single_banner_iframe");
    if (sdkBanner != null) {
      sdkBanner.innerHTML = "";
    }
    setBannerResponse(null);
  }

  function clearCatalog() {
    var catalog = document.getElementById("ek_offer_catalog");
    if (catalog) {
      const child = catalog.querySelector(`#ek-iframe`);
      if (child) {
        child.remove();
      }
    }
  }

  function buildGetBannerUrl() {
    var banner = document.getElementById("ek_single_banner_api");
    var daasEnv = localStorage.getItem("daas-env");
    var url = getDaaSDomain(daasEnv);
    url += "/offers-engine/api/merchant-offers?";
    if (bannerUtmSource) url += `utm_source=${bannerUtmSource}&`;
    if (bannerUtmMedium) url += `utm_medium=${bannerUtmMedium}&`;
    if (selectedMerchant) url += `mid=${selectedMerchant}&`;
    if (selectedLanguage) url += `pl=${selectedLanguage}&`;
    if (selectedDimension) {
      url += `d=${selectedDimension}&`;

      var dimensions = selectedDimension.toUpperCase().split("X");
      banner.style.width = dimensions[0] + "px";
      banner.style.height = dimensions[1] + "px";
    } else {
      banner.style.width = "100%";
    }
    url += "sc=api";
    return url;
  }

  const handleBannerDimensionChange = (event) => {
    setSelectedDimension(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const getButtonText = () => {
    switch (loadOption) {
      case SHOW_BANNER:
        return "Show Banner";
      case SHOW_CATALOG:
        return "Show Catalog";
      case SHOW_CATALOG_AND_BANNER:
        return "Show Banner and Catalog";
      default:
        return "Show";
    }
  };

  useEffect(() => {
    new OAuth2()
      .getAccessToken()
      .then((token) => {
        getBannerDimensions(token);
        getMerchants(token);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="TestBannerAndCatalog">
      <div className="container">
        <div className="row mt-5">
          <div className="col-4">
            <FormLabel>Select Merchant</FormLabel>
            <Select
              options={merchants}
              onChange={(selectedOption) => {
                setBannerUtmSource(null);
                setBannerUtmMedium(null);
                setSelectedMerchant(selectedOption.value);
                var utmParameters = getUtmParametersByMerchantName(
                  selectedOption.label
                );
                setUtmParameters(utmParameters);
                document.getElementById(
                  "utm-source-dropdown"
                ).selectedIndex = 0;
              }}
            />
          </div>
          <div className="col-4">
            <FormLabel>Select Dimension</FormLabel>
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={handleBannerDimensionChange}
            >
              <option value="" selected>
                Dimension
              </option>
              {bannerDimensions &&
                bannerDimensions.map((type) => (
                  <option value={type.code}>{type.code}</option>
                ))}
              ;
            </select>
          </div>
          <div className="col-4">
            <FormLabel>Select Language</FormLabel>
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={handleLanguageChange}
            >
              <option value="" selected>
                Language
              </option>
              <option value="en">English</option>
              <option value="ar">Arabic</option>
            </select>
          </div>
        </div>
        <FormLabel className="h6 mt-3 text-dark">UTM Parameters :</FormLabel>
        <div className="row">
          <div className="col-4">
            <select
              id="utm-source-dropdown"
              class="form-select"
              aria-label="Default select example"
              onChange={(event) => {
                setBannerUtmSource(event.target.value);
                setBannerUtmMedium(null);
              }}
            >
              <option value="" selected>
                Banner UTM Source
              </option>
              {utmParameters &&
                utmParameters["banner"] &&
                utmParameters["banner"]["utm_source"] &&
                utmParameters["banner"]["utm_source"].map(
                  (param) =>
                    param !== "" && <option value={param}>{param}</option>
                )}
              ;
            </select>
          </div>
          <div className="col-4">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(event) => {
                setBannerUtmMedium(event.target.value);
              }}
            >
              <option value="" selected>
                Banner UTM Medium
              </option>
              {utmParameters?.banner?.utm_medium
                ?.filter(Boolean)
                .map((param) => (
                  <option key={param} value={param}>
                    {param}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <select
              id="utm-source-dropdown"
              class="form-select"
              aria-label="Default select example"
              onChange={(event) => {
                setCatalogUtmSource(event.target.value);
                setCatalogUtmMedium(null);
              }}
            >
              <option value="" selected>
                Catalog UTM Source
              </option>
              {utmParameters &&
                utmParameters["catalog"] &&
                utmParameters["catalog"]["utm_source"] &&
                utmParameters["catalog"]["utm_source"].map(
                  (param) =>
                    param !== "" && <option value={param}>{param}</option>
                )}
              ;
            </select>
          </div>
          <div className="col-4">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(event) => {
                setCatalogUtmMedium(event.target.value);
              }}
            >
              <option value="" selected>
                Catalog UTM Medium
              </option>
              {utmParameters?.catalog?.utm_medium
                ?.filter(Boolean)
                .map((param) => (
                  <option key={param} value={param}>
                    {param}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <FormLabel>Fetch Banner via</FormLabel>
            <select
              id="load-banner-via-dropdown"
              class="form-select"
              aria-label="Default select example"
              onChange={(event) => {
                setLoadBannerVia(event.target.value);
              }}
            >
              <option value="sdk">SDK</option>
              <option value="api">API</option>
            </select>
          </div>
          <div className="col-4">
            <FormLabel>Which one to load?</FormLabel>
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(event) => {
                setLoadOption(event.target.value);
              }}
            >
              <option value="sb">Only Banner</option>
              <option value="sc">Only Catalog</option>
              <option value="scb">Both Banner and Catalog</option>
            </select>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <Button
              className="mw-20"
              variant="danger"
              onClick={handleToLoadBannerAndCatalog}
            >
              {getButtonText()}
            </Button>
          </div>
        </div>

        <div className="py-5">
          {(loadOption === SHOW_BANNER ||
            loadOption === SHOW_CATALOG_AND_BANNER) &&
            loadBannerVia === "sdk" && <EktarIframeBanner />}
          {(loadOption === SHOW_BANNER ||
            loadOption === SHOW_CATALOG_AND_BANNER) &&
            loadBannerVia === "api" && (
              <EktarApiBanner response={bannerResponse} />
            )}
          <div className="mt-5"></div>
          {(loadOption === SHOW_CATALOG ||
            loadOption === SHOW_CATALOG_AND_BANNER) && <EktarOfferCatalog />}
        </div>
      </div>
    </div>
  );
}

export default TestBannerAndCatalog;
