import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/Dashboard';

function App() {
    return (
        <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route path="/dashboard/*" element={<DashboardPage />} />
        </Routes>
    );
}

export default App;
