import React, { useEffect } from "react";
import daas from '@ektar-tech/daas-sdk-js';

import EktarIframeBanner from "./EktarIframeBanner";

function StarbucksPage() {
    useEffect(() => {
        var daasEnv = localStorage.getItem("daas-env");

        var mobileNumber = localStorage.getItem("mobile_number");
        var emailAddress = localStorage.getItem("email_address");
        var clientSegmentObj = localStorage.getItem("merchantSegments");
        var clientSegments = JSON.parse(clientSegmentObj);
        var memberIdsObj = localStorage.getItem("merchantMemberIds");
        var memberIds = JSON.parse(memberIdsObj);
        var starbucksId = localStorage.getItem("Starbucks");

        var dataPoint = new daas.com.ektar.daas_sdk.model.DataPoint();
        dataPoint.merchantId = starbucksId;
        dataPoint.merchantCustomerSegment = clientSegments.starbucks
        dataPoint.visitorId = mobileNumber
        dataPoint.pageId = "d866e496-3e0a-479d-b902-8be9832afe36"
        dataPoint.dimension = "100X120"
        dataPoint.latLng = "96.0000, 83.89080"
        dataPoint.preferredLanguage = "ENGLISH_UK"
        dataPoint.nationality = "IN"
        dataPoint.memberSince = "1991-01-28"
        dataPoint.hasCCInWallet = false
        dataPoint.ccBank = "HDFC"
        dataPoint.utmSource = "starbucks-ae"
        dataPoint.utmMedium = "banner"

        var ektarBanner = new daas.com.ektar.daas_sdk.core.EktarBanner(dataPoint);
        ektarBanner.ektar_daas_env = daasEnv;
        ektarBanner.loadBanner();
        
    }, []);

    return (
        <div className="DanubePage">
            <header>
                {/* Jumbotron */}
                <div className="text-white" style={{
                    backgroundImage: 'url("https://i.ytimg.com/vi/pyyr1p9x3h0/maxresdefault.jpg")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    objectFit: "cover"
                }}>
                    <div className="container py-5" style={{ width: '100%', height: '350px' }}>
                    </div>
                </div>
                {/* Jumbotron */}
            </header>
            {/* Products */}
            <section>
                <div className="container my-2">
                    <header className="mb-2">
                        <h4>ESPRESSO | FRAPPUCCINO | TEAVANA</h4>
                    </header>

                    <EktarIframeBanner />
                    <div className="py-3" />                   

                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://stories.starbucks.com/uploads/2021/02/SBX20210224-Spring2021-Starbucks-Iced-Pineapple-Matcha-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Non-dairy iced shaken espresso beverages and Oatly oatmilk</h5>
                                    <p className="card-text">$790.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://archive.starbucks.com/uploads/2018/12/thumb-40-hero-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">The Frappuccino Blended Beverage | Starbucks Archive</h5>
                                    <p className="card-text">$320.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://lh3.googleusercontent.com/H58DVpsN78ZlXBa7THbrBhrnO6gx1iloTcloLes53cHh-dQEwJWguSGgupcpGgKILQA3mevhT9P_OOMNLi064SBWpkRRC7r6lM9mBphs=w300-rw" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Starbucks blunder</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://www.onlinekade.lk/wp-content/uploads/2022/12/5711953072024-1-300x300.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Frappuccino</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://sureketo.com/images/starbucks-espresso-roast-ground-coffee.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Starbucks Espresso Roast Ground Coffee Keto</h5>
                                    <p className="card-text">$790.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://m.media-amazon.com/images/I/71NjSoxVjhL._AC_SS300_.jpg" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Starbucks Milk Coffee | Doubleshot Espresso Pack 0f 6 200ml Each</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">CStarbucks Ground Coffee—Medium Roast Coffee—Holiday Blend—100% Arabica</h5>
                                    <p className="card-text">$99.50</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border icon-hover px-2 pt-2"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                            <div className="card w-100 my-2 shadow-2-strong">
                                <img src="https://lh3.googleusercontent.com/5JmG77w_kwgomWGqGaqLmnrHorcVdnO1T5SHkwQKno59anqa802V3F3dRuK3I9TQBttuNj_cYisL0BzpvmdnvWJqR63Azg2MBAxekwQ=w300-rw" className="card-img-top" style={{ aspectRatio: '1 / 1' }} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">Starbucks | Cold coffee</h5>
                                    <p className="card-text">$120.00</p>
                                    <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Products */}
        </div>
    )
}

export default StarbucksPage