import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { useRef, useState } from "react";
import { Button, ButtonGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Button as CircularButton } from "rsuite";

import { getDaaSDomain } from '../utils';

import "rsuite/dist/rsuite.min.css";

function LoginPage() {
  const navigate = useNavigate();
  const navigateHome = () => navigate('/dashboard');

  const email = useRef();
  const password = useRef();

  const [getEnvironment, setEnvironment] = useState('dev');
  const [getApiDomain, setApiDomain] = useState('https://daas-dev.ektar.io');

  const [getEmail, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [getPassword, setPassword] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [loginButtonState, setLoginButtonState] = useState(false);
  const [registerButtonState, setRegisterButtonState] = useState('');

  const rememberMeCookie = Cookies.get('rememberMe');

  function showAlert(message, isSuccess) {
    var alertPlaceholder = document.getElementById('alert-placeholder');
    var innerTag = '<div class="alert alert-danger" role="alert">' + message + '</div>';
    if (isSuccess) {
      innerTag = '<div class="alert alert-success" role="alert">' + message + '</div>';
    }
    alertPlaceholder.innerHTML = innerTag;
    $(alertPlaceholder).show();
  }

  function hideAlert() {
    var alertPlaceholder = document.getElementById('alert-placeholder');
    $(alertPlaceholder).hide();
  }

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // "Enter" key was pressed, call the onClick function
      handleLoginAction();
    }
  };

  function handleRegister() {

    showLoaderInRegisterButton();
    var url = getApiDomain + '/merchant/user/register';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        emailAddress: email.current.value,
        password: password.current.value,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then(response => response.json())
      .then(response => {
        hideLoaderInRegisterButton()
        showAlert("Successfully registered. Please Login to continue", true);
        console.log(response);
      })
      .catch((err) => {
        hideLoaderInRegisterButton()
        showAlert("Registration failed, please try again with correct credentials", false);
        console.log(err.message);
      });
  }

  function handleLoginAction() {

    showLoaderInLoginButton(true);
    window.localStorage.setItem('daas-env', getEnvironment);
    window.localStorage.setItem('daas-api-domain', getApiDomain);

    var url = getApiDomain + '/merchant/user/login';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        emailAddress: email.current.value,
        password: password.current.value,
      }),
      _headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      get headers() {
        return this._headers;
      },
      set headers(value) {
        this._headers = value;
      },
    })
      .then(response => {
        console.log("Response is : " + response);
        return response.json()
      })
      .then(response => {
        showAlert("Successfully logged in", true);
        console.log(response);

        var userAgent = navigator.userAgent;
        var deviceId = btoa(userAgent);

        localStorage.setItem('user_id', response.id);
        localStorage.setItem('name', response.name);
        localStorage.setItem('mobile_number', response.mobileNumber);
        localStorage.setItem('email_address', response.emailAddress);
        localStorage.setItem('client_segment', response.clientSegment);
        localStorage.setItem('device_id', deviceId);
        localStorage.setItem('device_type', 'WEB');
        localStorage.setItem('merchantSegments', JSON.stringify(response.merchantSegments));
        localStorage.setItem('merchantMemberIds', JSON.stringify(response.merchantMemberIds));
        localStorage.setItem("user", response);
      })
      .then((response) => {
        fetchMerchantId();
      })
      .catch((err) => {
        hideLoaderInLoginButton();
        showAlert("Invalid credentials, please try again", false);
        console.log(err.message);
      });
  }

  function fetchMerchantId() {
    var url = getApiDomain + '/merchant/user/get-merchants-details';
    url = url + '/danube,starbucks,talabat,siemens,6thstreet';
    fetch(url, {
      method: 'GET',
    }).then(response => response.json())
      .then(response => {
        hideLoaderInLoginButton();
        console.log(response);
        localStorage.setItem('Danube', response['Danube Home']);
        localStorage.setItem('Starbucks', response['Starbucks']);
        localStorage.setItem('Talabat', response['Talabat']);
        localStorage.setItem('Siemens', response['Siemens']);
        localStorage.setItem('SixthStreet', response['6thStreet']);

        navigateHome();
      })
      .catch((err) => {
        hideLoaderInLoginButton();
        showAlert("Error while fetching merchant details, please try again", false);
        console.log(err.message);
      });
  }

  function handleClick(e) {
    console.log(e.target.innerText);
    var env = e.target.innerText.toLowerCase();
    setEnvironment(env)
    const domain = getDaaSDomain(env); 
    if (domain) { 
      setApiDomain(domain); 
    } else { 
      console.error(`Failed to get domain for environment: ${env}`);
     }
  }

  function showLoaderInLoginButton() {
    setLoginButtonState(true);
    setIsButtonEnabled(false);
  }

  function hideLoaderInLoginButton() {
    setLoginButtonState(false);
    setIsButtonEnabled(true);
  }

  function showLoaderInRegisterButton() {
    setRegisterButtonState(true);
    setIsButtonEnabled(false);
  }

  function hideLoaderInRegisterButton() {
    setRegisterButtonState(false);
    setIsButtonEnabled(true);
  }

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    var isValid = validateEmail(newEmail);
    setIsValidEmail(isValid);
    setIsButtonEnabled(isValid && getPassword.length >= 2);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsButtonEnabled(isValidEmail && newPassword.length >= 2);
  };

  const handleProgressChange = (event) => {
    setLoginButtonState(true)
    setTimeout(() => {
      setLoginButtonState(false)
    }, 3000)
  };

  return (
    <section class="vh-100" style={{ backgroundColor: '#508bfc' }}>
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card shadow-2-strong" style={{ borderRadius: '1rem' }}>
              <div class="card-body p-5 text-center">

                <div class="mb-5">
                  <img src="https://www.freepnglogos.com/uploads/flipkart-logo-png/flipkart-com-logo-internet-ltd-state-of-kerala-10.png" height={50} />
                </div>

                <div class="form-floating mb-4">
                  <input type="email" ref={email} id="typeEmailX-2" class="form-control form-control-lg" value={getEmail} onChange={handleEmailChange} />
                  <label class="form-label" for="typeEmailX-2">Email</label>
                </div>

                <div class="form-floating mb-4">
                  <input type="password" ref={password} id="typePasswordX-2" class="form-control form-control-lg" value={getPassword} onChange={handlePasswordChange} 
                  onKeyDown={handleKeyDown}/>
                  <label class="form-label" for="typePasswordX-2">Password</label>
                </div>

                <ButtonGroup size="lg" aria-label="Environments" onClick={handleClick}>
                  <Button variant={getEnvironment === 'localhost'?'secondary': 'outline-secondary'}>Localhost</Button>
                  <Button variant={getEnvironment === 'dev'?'secondary': 'outline-secondary'}>Dev</Button>
                  <Button variant={getEnvironment === 'uat'?'secondary': 'outline-secondary'}>UAT</Button>
                  <Button variant={getEnvironment === 'demo'?'secondary': 'outline-secondary'}>Demo</Button>
                </ButtonGroup>

                <div class="form-check d-flex justify-content-start mt-4 mb-4">
                  <input class="form-floating form-check-input" type="checkbox" value="" id="form1Example3" />
                  <label class="form-floating form-check-label" for="form1Example3"> Remember password </label>
                </div>
                <CircularButton style={{backgroundColor: '#3A71CA',}} appearance="primary" size="lg" className="btn-primary btn-block text-white" 
                            type="submit" onClick={() => handleLoginAction()}  disabled={!isButtonEnabled} loading={loginButtonState}>LOGIN</CircularButton>
                <CircularButton style={{backgroundColor: '#3A71CA',}} appearance="primary" size="lg" className="btn-primary btn-block text-white" 
                            type="submit" onClick={() => handleRegister()} disabled={!isButtonEnabled} loading={registerButtonState}>REGISTER</CircularButton>
                <div id="alert-placeholder" class="mt-4" style={{ display: 'none' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginPage