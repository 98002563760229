import React, { useEffect } from "react";
import daas from '@ektar-tech/daas-sdk-js';
import EktarOfferCatalog from "./EktarOfferCatalog";

function OfferCatalogPage() {
    

    useEffect(() => {
        var daasEnv = localStorage.getItem("daas-env");
        var danubeId = localStorage.getItem("Danube");

        var dataPoint = new daas.com.ektar.daas_sdk.model.CatalogDataPoint();
        dataPoint.merchantId = danubeId;
        dataPoint.showLogo = false;
        dataPoint.showFooter = false;
        dataPoint.subscriber = true;
        dataPoint.pageCategory = "Testing";
        dataPoint.utmSource = "danube-ae"
        dataPoint.utmMedium = "offer-catalog"

        var ektarBanner = new daas.com.ektar.daas_sdk.core.EktarCatalog(dataPoint);
        ektarBanner.ektar_daas_env = daasEnv;
        ektarBanner.loadOfferCatalog();

    }, []);

    return (
        <EktarOfferCatalog />
    )
}

const styles = {
    width: "100%",
    border: "none",
};

export default OfferCatalogPage