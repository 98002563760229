import React, { useEffect } from "react";
import daas from '@ektar-tech/daas-sdk-js';

import EktarIframeBanner from "./EktarIframeBanner";

function SiemensPage() {
    useEffect(() => {
        var daasEnv = localStorage.getItem("daas-env");

        var mobileNumber = localStorage.getItem("mobile_number");
        var emailAddress = localStorage.getItem("email_address");
        var clientSegmentObj = localStorage.getItem("merchantSegments");
        var clientSegments = JSON.parse(clientSegmentObj);
        var memberIdsObj = localStorage.getItem("merchantMemberIds");
        var memberIds = JSON.parse(memberIdsObj);
        var siemensId = localStorage.getItem("Siemens");

        var dataPoint = new daas.com.ektar.daas_sdk.model.DataPoint();
        dataPoint.merchantId = siemensId;
        dataPoint.visitorId = memberIds.siemens;
        dataPoint.merchantCustomerSegment = clientSegments.siemens
        dataPoint.pageId = "d866e496-3e0a-479d-b902-8be9832afe36"
        dataPoint.dimension = "100X120";
        dataPoint.latLng = "96.0000, 83.89080"
        dataPoint.preferredLanguage = "ENGLISH_UK"
        dataPoint.nationality = "IN"
        dataPoint.age = 21
        dataPoint.ccBank = "HDFC"
        dataPoint.email = emailAddress
        dataPoint.mobile = mobileNumber
        dataPoint.utmSource = "siemens-ae"
        dataPoint.utmMedium = "banner"

        var ektarBanner = new daas.com.ektar.daas_sdk.core.EktarBanner(dataPoint);
        ektarBanner.ektar_daas_env = daasEnv;
        ektarBanner.loadBanner();
        
    }, []);


    return (
        <div className="SiemensPage">
            <header>
            </header>
            <section>
                <div className="container siemens-banner-container">
                    <EktarIframeBanner className="siemens-iframe-banner"/>
                    <div className="py-1" />
                </div>
            </section>
        </div>
    )
}

export default SiemensPage