package com.ektar.daas_sdk.utils

class Constants {

	companion object {

		// data-points
		var EKID: String = "ekid"

		// js constants
		var IFRAME_BANNER_ID: String = "ek_single_banner_iframe"
		var SIMPLE_BANNER_ID: String = "ek_single_banner"

		var OFFER_CATALOG_ID: String = "ek_offer_catalog"

		var GET_IFRAME_HEIGHT: String = "heightForEktarIFrame"
		var SAVE_EKID_IN_LOCAL_STORAGE: String = "saveEkIdInLocalStorage"
	}
}
