import React, { useEffect } from "react";
import daas from '@ektar-tech/daas-sdk-js'; 

import EktarIframeBanner from "./EktarIframeBanner";

function DanubePage() {

    useEffect(() => {
        var daasEnv = localStorage.getItem("daas-env");

        var userId = localStorage.getItem("user_id");
        var clientSegmentObj = localStorage.getItem("merchantSegments");
        var clientSegments = JSON.parse(clientSegmentObj);
        var memberIdsObj = localStorage.getItem("merchantMemberIds");
        var memberIds = JSON.parse(memberIdsObj);
        var danubeId = localStorage.getItem("Danube");
    
        var dataPoint = new daas.com.ektar.daas_sdk.model.DataPoint();
        dataPoint.merchantId = danubeId;
        dataPoint.merchantCustomerSegment = clientSegments.danube;
        dataPoint.visitorId = memberIds.danube
        dataPoint.pageId = "d866e496-3e0a-479d-b902-8be9832afe36"
        dataPoint.preferredLanguage = "en"
        dataPoint.nationality = "IN"
        dataPoint.utmSource = "danube-ae"
        dataPoint.utmMedium = "banner"
    
        var ektarBanner = new daas.com.ektar.daas_sdk.core.EktarBanner(dataPoint);
        ektarBanner.ektar_daas_env = daasEnv;
        ektarBanner.loadBanner();
    }, []);

    return (
        <div className="DanubePage">
            <header>
                {/* Jumbotron */}
                <div className="text-white " style={{
                    backgroundImage : 'url("https://t3.ftcdn.net/jpg/05/09/36/52/360_F_509365220_oNhwwiKfwkIkY8mgkSIWRNnbzzs4H1P8.jpg")',
                    backgroundRepeat : "no-repeat",
                    backgroundPosition : "center center",
                    objectFit :"cover"
                    }}>
                    <div className="container py-2" style={{width : '100%', height : '350px'}}>
                    </div>
                </div>
                {/* Jumbotron */}
            </header>
            {/* Products */}
            <section>
            <div className="container my-5">
                <header className="mb-4">
                <h4>New products</h4>
                </header>

                <EktarIframeBanner/>
                <div className="py-3"/>

                <div className="mb-4"></div>
                <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://cdn.furnituremagik.com/wp-content/uploads/2018/12/protea_solid_wood_three_seater_sofa_by_furniture_magik_by_furniture_magik-300x300.jpg" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h4 className="card-title">Protea Solid Wood Three Seater Sofa By Furniture Magik</h4>
                        <p className="card-text">$790.50</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://www.spacepanda.in/wp-content/uploads/2022/02/4-17-300x300.png" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h5 className="card-title">Spacepanda Ogeebry Wicker Sofa Set (2 Seater & 2 Single Seater & 1 Table)</h5>
                        <p className="card-text">$320.00</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://www.kajainteriors.com/wp-content/uploads/sites/2/2023/07/an1-300x300.jpeg" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h5 className="card-title">Furniture, design & Realisation | Kaja</h5>
                        <p className="card-text">$120.00</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://cdn.furnituremagik.com/wp-content/uploads/2021/12/Primitive_large_size_office_table_by_furniture_magik_2-300x300.jpg" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h5 className="card-title">Primitive Large Size Office Table By Furniture Magik</h5>
                        <p className="card-text">$120.00</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://rukminim1.flixcart.com/image/300/300/ky3b0y80/diwan-settee/8/v/h/3-cream-rosewood-sheesham-91-vf-1051-varsha-furniture-natural-original-imagaezquz4wkdkj.jpeg" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h5 className="card-title">VARSHA FURNITURE Solid Wooden Diwan | Material- Solid Sheesham Wood |</h5>
                        <p className="card-text">$790.50</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://luxox.shop/cdn/shop/products/Cane-_-Rattan-Furniture-Sofa-Set-Deck-01_300x.jpg?v=1673502529" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h5 className="card-title">Cane & Rattan Furniture - Sofa Set - Deck</h5>
                        <p className="card-text">$120.00</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://woodpeckerz.in/cdn/shop/products/Bobran-Modern-Velvet-3-Seater-Sofa-by-Christopher-Knight-Home_5_300x300.webp?v=1661695364" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h5 className="card-title">Christopher Modern Velvet 3-seat Sofa – WoodPeckerz Furniture</h5>
                        <p className="card-text">$99.50</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border icon-hover px-2 pt-2"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div className="card w-100 my-2 shadow-2-strong">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeoXJXr2D3W0YLsjWXv5P0sCXwtGQEb0UMtw&usqp=CAU" className="card-img-top" style={{aspectRatio: '1 / 1'}} />
                    <div className="card-body d-flex flex-column">
                        <h5 className="card-title">Wooden Cot | Teak Wood | X-Collection from Nemophilist Interiors</h5>
                        <p className="card-text">$120.00</p>
                        <div className="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto">
                        <a href="#!" className="btn btn-primary shadow-0 me-1">Add to cart</a>
                        <a href="#!" className="btn btn-light border px-2 pt-2 icon-hover"><i className="fas fa-heart fa-lg text-secondary px-1" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            {/* Products */}
        </div>
    )
}

export default DanubePage